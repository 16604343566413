import React, { useEffect, useState } from 'react';
import * as styles from './Accordion.module.css';

type ColorType = 'primary' | 'secondary';

type AccordionItem = {
  question: string;
  answer: string;
};

type AccordionProps = {
  title: string;
  subTitle: string;
  colorType: ColorType;
  items: AccordionItem[];
};

const Accordion: React.FC<AccordionProps> = ({
  items,
  title,
  subTitle,
  colorType,
}) => {
  let accordionSectionClass = styles.accordionSectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    accordionSectionClass = styles.accordionSectionPrimary;
  } else if (colorType === 'secondary') {
    accordionSectionClass = styles.accordionSectionSecondary;
  }

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  useEffect(() => {
    setOpenIndex(null);
  }, []);

  useEffect(() => {
    // FAQの構造化データを生成
    const faqStructuredData = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: items.map((item, index) => ({
        '@type': 'Question',
        name: item.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: item.answer,
        },
      })),
    };

    // 構造化データを埋め込むスクリプトタグを作成
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(faqStructuredData);
    document.head.appendChild(script);

    // クリーンアップ: コンポーネントがアンマウントされたらスクリプトを削除
    return () => {
      document.head.removeChild(script);
    };
  }, [items]);

  return (
    <section className={accordionSectionClass} id="accordion">
      <div className={styles.accordionSectionInner}>
        <h2 className={styles.head}>
          <span className={styles.headText}>{title}</span>
          <span className={styles.headSubtext}>{subTitle}</span>
        </h2>
        <ul className={styles.accordionArea}>
          {items.map((item, index) => (
            <li key={index}>
              <section>
                <h3
                  className={
                    openIndex === index
                      ? `${styles.title} ${styles.close}`
                      : styles.title
                  }
                  onClick={() =>
                    setOpenIndex(index === openIndex ? null : index)
                  }
                >
                  {item.question}
                </h3>
                {openIndex === index && (
                  <div className={openIndex === index ? styles.open : ''}>
                    {item.answer}
                  </div>
                )}
              </section>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Accordion;
